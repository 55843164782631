@import "src/styles/global/_global.scss";
.footer {
    width: 100%;
    @include flex(center, center);
    padding: 1.5rem 1rem;
    border-top: 1px solid $border-color;
    font-size: 12px;
    font-weight: 500;

    .nav {
        flex: 1;
        @include flex(center, center, column);
    }
}
