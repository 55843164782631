@import "src/styles/global/_global.scss";
.alert {
    font-size: 14px;
    margin: 1.5rem 1rem;
    margin-bottom: 0 !important;
    width: calc(100% - 2rem);
    @include flex(normal, flex-start);
    gap: 0.5rem;

    @include media-breakpoint-down(md) {
        width: 100%;
        margin: 0 !important;
        border-radius: 0 !important;
        border-width: 0 0 1px 0 !important;
        flex-direction: column;
    }

    &__icon {
        margin-top: -0.5rem;
        margin-left: -0.5rem;
    }

    &__link {
        font-weight: bold;

        &--warning {
            color: $warning-text-emphasis;
        }

        &--danger {
            color: $danger-text-emphasis;
        }
    }
}
