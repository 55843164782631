@import "src/styles/global/_global.scss";
.container {
    max-width: 27.5rem;
    height: 46rem;
    @include flex(center, center, column);
    padding: 0 1rem;
    background-color: $color-blue-1;
    border-radius: $border-lg;

    @include media-breakpoint-down(md) {
        max-width: 100%;
        height: 100%;
        border-radius: 0;
    }

    .header {
        text-align: center;
        @include flex(center, center, column);
        gap: 0.5rem;
        margin-bottom: 1.5rem;

        &__title {
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            color: $color-white-4;
        }

        &__subtitle,
        &--error {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: $color-gray-3;
        }

        &--error {
            color: $color-red-1;
        }
    }
}
