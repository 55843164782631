@import "src/styles/global/_global.scss";
.card {
    &__container {
        padding-top: env(safe-area-inset-top) !important;
        padding: 0;
        height: 46rem;
        width: 27.5rem;

        @include media-breakpoint-down(md) {
            height: 100%;
            width: 100%;
        }
    }

    &__header {
        width: 100%;
        @include flex(space-between, center);
        padding: 1.5rem 1rem;
        border-bottom: 1px solid $border-color;
        font-size: 18px;
        font-weight: 600;
    }

    &__content {
        @include flex(flex-start, flex-start, column);
        width: 100%;
        flex: 1;
        padding: 0 1rem;
        height: 100%;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 0.25rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary;
            border-radius: 0.5rem;
        }

        .settings {
            width: 100%;
            @include flex(space-between, center);
            border-top: 1px solid $border-color;
            padding: 1rem 0;
        }

        .item {
            @include flex(flex-start, flex-start, column);
            font-weight: 500;
            color: $black;

            &__subtitle {
                color: $gray;
                font-weight: normal;
            }
        }

        .value {
            @include flex(normal, center);
            gap: 0.5rem;
            color: $gray;
        }
    }
}

.alert--danger {
    background-color: #fee4e2;
    width: 3rem;
    height: 3rem;
    @include flex(center, center);
    border-radius: 100%;
}

.lang {
    padding: 1.5rem;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    color: $black;
    cursor: pointer;

    & + .lang {
        border-top: 1px solid $border-color;
    }

    &--lighter {
        font-weight: 400;
        color: $gray;
    }
}
