@import "src/styles/global/_global.scss";
.card {
    &__container {
        padding-top: env(safe-area-inset-top) !important;
        padding: 0;
        height: 46rem;
        width: 27.5rem;

        @include media-breakpoint-down(md) {
            height: 100%;
            width: 100%;
        }
    }

    &__header {
        width: 100%;
        @include flex(space-between, center);
        padding: 1.5rem 1rem;

        .back {
            @include flex(normal, center);
            gap: 0.25rem;
            font-size: 14px;
            font-weight: 600;
            color: $gray;
        }
    }

    &__content {
        @include flex(center, stretch, column);
        gap: 1.5rem;
        width: 100%;
        flex: 1;
        padding: 0 1rem;
        height: 100%;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 0.25rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary;
            border-radius: 0.5rem;
        }

        .title {
            @include flex(center, center, column);
            gap: 0.5rem;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: $gray;
            text-align: center;

            &--bold {
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
                color: $black;
            }
        }
    }
}

.error {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: $color-red-2;
    margin: 0 1rem;
}

.button {
    font-weight: 500 !important;
    margin: 0.5rem 1rem 1.5rem;
    width: calc(100% - 2rem);
}
