@import "src/styles/global/_global.scss";
.card {
    height: 34rem;
    max-width: 27.5rem;
    @include flex(center, center, column);
    gap: 1rem;
    padding: 2rem;
    background-color: white;
    box-shadow: 0px 1px 3px 0px $box-shadow;
    border-radius: $border-lg;

    @include media-breakpoint-down(md) {
        width: 100%;
        height: 100%;
        border: none;
        max-width: unset;
        border-radius: 0;
    }

    &__content,
    &__buttons {
        width: 100%;
        @include flex(center, center, column);
    }

    &__buttons {
        gap: 1rem;
    }

    &__content {
        flex: 1;
        gap: 0.5rem;
    }
}
