*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

body {
    box-sizing: border-box;
    margin: 0;
    font-family: -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }

    textarea.form-control {
        background-color: white;
        resize: none;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px 0px #1018280d;
    }

    // My Custom Styles
    .app__container {
        height: 100vh;
        display: flex;
        flex-direction: column;

        .container {
            flex: 1;
        }
    }

    .font-size-sm {
        font-size: 12px;
    }

    .font-size-md {
        font-size: 14px;
    }

    .font-size-default {
        font-size: 16px;
    }

    .font-size-lg {
        font-size: 18px;
    }

    .font-size-xl {
        font-size: 20px;
    }

    .fw-medium {
        font-weight: 500;
    }

    .fw-semibold {
        font-weight: 600;
    }

    .text-gray {
        color: #475467;
    }

    .pointer {
        cursor: pointer;
    }

    // Bootstrap Styles
    .btn-inherit:hover {
        --bs-btn-hover-color: #003f3c;
    }

    .btn-light {
        color: #475467 !important;
        background-color: transparent !important;
        border: none !important;
    }

    .alert-warning {
        --bs-alert-bg: #fffaeb !important;
        --bs-alert-border-color: #fedf89 !important;
    }

    .alert-danger {
        --bs-alert-bg: #fffbfa !important;
        --bs-alert-border-color: #fecdca !important;
    }

    .badge {
        padding: 0.25rem 0.75rem !important;
        border-radius: 1000px !important;
    }

    .badge-primary {
        background-color: #f3f6f6 !important;
        color: #004a46 !important;
        border: 1px solid #ccdbda;
    }

    .badge-error {
        background-color: #fef3f2 !important;
        color: #b42318 !important;
        border: 1px solid #fecdca;
    }
}

@media screen and (max-width: 767.98px) {
    .app__container {
        .container {
            margin: 0;
            padding: 0;
            min-width: 100%;
            overflow: hidden;
        }
    }
}
