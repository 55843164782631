@import "src/styles/global/_global.scss";
.logo {
    border-radius: 12px;
}

.title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: $black;
    text-align: center;
}

.subtitle {
    @include flex(center, center, column);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $gray;
    text-align: center;
}
