@import "src/styles/global/_global.scss";
.title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: $black;
}

.subtitle,
.error {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $gray;
}

.error {
    color: $color-red-2;
}
