@import "src/styles/global/_global.scss";
.card {
    &__container {
        width: 27.5rem;
    }

    &__icon {
        @include flex(center, center);
        padding: 0.5rem;
        width: 3rem;
        height: 3rem;
        border: 1px solid $border-color;
        box-shadow: 0px 1px 2px 0px $box-shadow;
        border-radius: $border-lg;
        margin-bottom: 1rem;
    }

    &__title {
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        color: $black;
    }

    &__subtitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $gray;
    }
}
