//Customize Bootstrap's Variables
//Colors
$primary: #004a46;
$black: #101828;
$gray: #475467;

$light-rgb: #f9fafb;

$body-bg: #f9fafb;
$body-color: #101828;

$btn-color: #004a46;

$warning-text-emphasis: #b54708;

$danger-text-emphasis: #b42318;

$color-border: #1f242f;

//Fonts
$font-family-base: -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;

$badge-font-weight: 400;

//My Variables
//Colors
$box-shadow: #1018280f;
$box-shadow-primary: #e6eded;
$box-shadow-error: #fee4e2;
$border-color: #eaecf0;

$color-gray-1: #161b26;
$color-gray-2: #333741;
$color-gray-3: #94969c;
$color-gray-4: #344054;

$color-white-1: #f2f4f7;
$color-white-2: #cecfd2;
$color-white-3: #f0f1f1;
$color-white-4: #f5f5f6;
$color-white-5: #d0d5dd;

$color-blue-1: #0c111d;

$color-red-1: #f97066;
$color-red-2: #d92d20;

//Borders
$border-lg: 12px;
