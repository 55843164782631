@import "src/styles/global/_global.scss";
.download {
    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        gap: 0.75rem;

        .h5 {
            color: #004a46;
            font-size: 1rem;
        }

        .h1 {
            font-size: 2rem;
        }

        @include media-breakpoint-up(md) {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
    }

    .cta {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 1.5rem;
        .h4 {
            font-size: 1.75rem;
            line-height: 1.4;
        }
        .buttons {
            display: flex;
            gap: 0.75rem;
        }
    }

    .footnote {
        color: #475467;
        text-align: center;
        padding: 1.5rem;
    }
}
