@import "src/styles/global/_global.scss";
.title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: $black;
    text-align: center;
}

.subtitle,
.error {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $gray;
    text-align: center;
}

.error {
    font-weight: 500;
    color: $color-red-2;
}
