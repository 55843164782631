@import "src/styles/global/_global.scss";
.card {
    &__container {
        padding-top: env(safe-area-inset-top) !important;
        padding: 0;
        height: 46rem;
        width: 27.5rem;

        @include media-breakpoint-down(md) {
            height: 100%;
            width: 100%;
        }
    }

    &__header {
        width: 100%;
        @include flex(space-between, center);
        padding: 1.5rem 1rem;
        border-bottom: 1px solid $border-color;
        font-size: 18px;
        font-weight: 600;
    }

    &__content {
        width: 100%;
        flex: 1;
        padding: 1.5em 1rem;
        @include flex(space-between, center, column);
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 0.25rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary;
            border-radius: 0.5rem;
        }
    }

    &__information {
        margin-top: 3rem;
        @include flex(center, center, column);
        gap: 0.5rem;
    }
}
