@import "src/styles/global/_global.scss";
.label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $color-gray-4;
}

.container {
    border: 1px solid $color-white-5;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px $box-shadow;

    &--default {
        &:focus-within {
            border-color: $primary;
            box-shadow: 0 0 0 0.25rem $box-shadow-primary;
        }
    }

    &--error {
        &:focus-within {
            border-color: $color-red-2;
            box-shadow: 0 0 0 0.25rem $box-shadow-error;
        }
    }

    .input {
        background-color: $white;
        border: none;
        padding: 0.625rem 0.875rem;

        &:hover,
        &:focus {
            border: none;
            box-shadow: none;
            background-color: $white;
        }

        &--borderless {
            border-right: 0;
        }
    }

    .postfix {
        background-color: $white;
        border: none;
        padding: 0.625rem 1rem;
        cursor: pointer;
    }
}

.hint,
.error {
    margin-top: 0.375rem;
    margin-bottom: 0 !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: $gray;
}

.error {
    color: $color-red-2;
}
