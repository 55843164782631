@import "src/styles/global/_global.scss";
.digit {
    border: 1px solid $color-gray-2;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;

    &--filled {
        border-color: $color-white-3;
        background-color: $color-white-3;
    }
}

.nums {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;

    &__pad {
        width: calc(33.33% - 2.25rem);
        @include flex(center, center);
        cursor: pointer;
    }

    &__text {
        @include flex(center, center);
        background-color: $color-gray-1;
        border: 1px solid $color-gray-2;
        box-shadow: 0px 1px 2px 0px #1018280d;
        width: 5rem;
        height: 5rem;
        border-radius: 100%;
        font-size: 30px;
        font-weight: 500;
        color: $color-white-2;
    }
}
