@import "src/styles/global/_global.scss";
$back-height: 4.5rem;

.home {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .card {
        &__container {
            padding-top: env(safe-area-inset-top) !important;
            padding: 0;
            height: 46rem;
            width: 27.5rem;

            @include media-breakpoint-down(md) {
                height: 100%;
                width: 100%;
            }
        }

        &__header {
            width: 100%;
            @include flex(space-between, center);
            padding: 1.5rem 1rem;
            border-bottom: 1px solid $border-color;
            font-size: 18px;
            font-weight: 600;
        }

        &__content {
            display: flex;
            width: 100%;
            flex: 1;
            margin: 1.5rem 0;
            padding: 0 1rem;
            height: 100%;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 0.25rem;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $primary;
                border-radius: 0.5rem;
            }
        }

        &__item {
            @include flex(normal, normal, column);
            padding: 1rem 0;
            font-weight: bold;

            & + .card__item {
                border-top: 1px solid $border-color;
            }
        }

        &__back {
            @include flex(normal, center);
            gap: 0.25rem;
            font-size: 14px;
            font-weight: 600;
            color: $gray;
            position: fixed;
            top: 4.7rem;
            background: white;
            width: 100%;
            height: $back-height;
            padding: 1.5rem 1rem;
            cursor: pointer;
            border-top: 1px solid $border-color;
        }

        &--detail {
            margin: $back-height 1rem 0;
        }

        &__empty {
            @include flex(center, center, column);
            gap: 0.5rem;
            padding: 1.5rem 2rem;
            text-align: center;
            font-size: 14px;
        }

        &__image {
            @include flex(center, center);
            margin-bottom: 0.75rem;
        }
    }
}
